@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    color-scheme: light only;
  }
  * {
    @apply font-montserrat;
  }
  html,
  body {
    @apply w-full cursor-default bg-white text-white;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .rec.rec-arrow:disabled {
    visibility: hidden;
  }
  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    margin: 50px;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 100vh;
    border: 3px solid #1a1732;
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
  .link-hover {
    @apply transition-colors duration-300 hover:text-gray-300;
  }
  .element-shadow {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.4);
  }
  .border-glow-sm {
    box-shadow: inset 0 0 6px rgba(237, 222, 255, 1),
      0 0 6px rgba(237, 222, 255, 1);
  }
  .border-glow-lg {
    box-shadow: inset 0 0 6px rgba(237, 222, 255, 1),
      0 0 6px rgba(237, 222, 255, 1), inset 0 0 8px rgba(237, 222, 255, 0.5),
      0 0 8px rgba(237, 222, 255, 0.5), inset 0 0 15px rgba(237, 222, 255, 1),
      0 0 15px rgba(237, 222, 255, 1);
  }
  .text-shadow {
    text-shadow: 0 0 10px #fff;
  }
  .border-glow-shadow-lg {
    box-shadow: inset 0 0 6px rgba(237, 222, 255, 1),
      0 0 6px rgba(237, 222, 255, 1), inset 0 0 8px rgba(237, 222, 255, 0.5),
      0 0 8px rgba(237, 222, 255, 0.5), inset 0 0 15px rgba(237, 222, 255, 1),
      0 0 15px rgba(237, 222, 255, 1), 14px -13px 19px #3724ba;
  }
}
